import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Navbar from '../Navbar';
import Foooter from '../Foooter';
import '../../style/ContentView.css';
import { useAuth } from '../../context/AuthContext.js'; 
import { useNavigate } from 'react-router-dom';
import FooterMovil from '../FooterMovil';

function AboutUs() {
    const [content, setContent] = useState('');
    const { authToken } = useAuth();
    
    const navigate = useNavigate();

    useEffect(() => {
        fetchContent();
    }, []);

    const fetchContent = async () => {
        try {
            const response = await axios.get(process.env.REACT_APP_BACKENDIP + "content/content/Nosotros", {
                headers: { Authorization: `Bearer ${authToken}` }
            });
            setContent(response.data.body);
        } catch (error) {
            console.error("Error fetching content:", error);
        }
    };

    return (
        <>
        <Navbar/>
        <div className="content-container">
            <div className="content-body-a">
                <h1>Sobre Nosotros</h1>
                <div dangerouslySetInnerHTML={{ __html: content }} />
            </div>
        </div>
        <div className="footer">
        <Foooter/>
        </div>
        <div className="footer-movil">
        <FooterMovil/>  
        </div>
        </>
    );
}

export default AboutUs;
