import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Navbar from "../Navbar";
import Foooter from "../Foooter";
import { useParams } from "react-router-dom";
import "../../style/InfoCar.css";
import axios from "axios";
import FooterMovil from "../FooterMovil";
import PopupLogged from "../PopupLogged";
import { useAuth } from "../../context/AuthContext.js";
import Popup360 from '../Popup360'; // Asegúrate de que la ruta sea correcta

function InfoCar() {
  const [showComponentA, setShowComponentA] = useState(true);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [link360, setLink360] = useState("");
  const { authToken } = useAuth();

  const [showModal, setShowModal] = useState(false);

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  const toggleComponent = () => {
    setShowComponentA(!showComponentA);
  };

  const [car, setCar] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const { id } = useParams();

  const [images, setimages] = useState([]);

  const [mainImage, setMainImage] = useState(images[0]);

  const handleImageClick = (imageUrl) => {
    setMainImage(imageUrl);
  };

  function checkBoolean(value) {
    return value ? "Si" : "No";
  }

  function formatNumberWithCommas(number) {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  function formatDate(dateString) {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
    const year = String(date.getFullYear()).slice(-2); // Get last two digits of the year

    return `${day}/${month}/${year}`;
  }

  const solicitarCompra = async () => {
    if(authToken){
      const data = {
        data: "data",
      };
      if(authToken){
        const res = await axios.post(
          process.env.REACT_APP_BACKENDIP + "ventas/solicitudcompra/" + id,
          data,
          { headers: { Authorization: "bearer " + authToken } }
        );
      }
    }
    const phoneNumber = "50499700699";
    const message = `quiero comprar el vehiculo ` + car.marca + " " + car.modelo + " " + car.anio + " numero de lote " + car.lote;
    const url = 'https://wa.me/' + phoneNumber + '?text=' + encodeURIComponent(message)
    await window.open(url, '_blank')
  };

  useEffect(() => {
    const fetchAllCarros = async () => {
      setLoading(true);
      try {
        const response = await axios.get(
          process.env.REACT_APP_BACKENDIP + "carro/" + id
        );
        setCar(response.data);
        let imageUrls = response.data.imagenes
        imageUrls = imageUrls.map(url => url.replace("http://", "https://"));
        setMainImage(imageUrls[0]);
        setimages(imageUrls);
        setLink360(response.data.link360);
        setError(null);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setError("Failed to fetch data");
        setCar([]);
        setLoading(false);
      }
    };
    fetchAllCarros();
  }, [id]);

  const [otherloading, othersetloading] = useState(false)

  const viewRecent =  async () => {
    if(otherloading){
      return 
    }

    othersetloading(true);

    const data = {}
    try {
      await axios.post(process.env.REACT_APP_BACKENDIP + "carro/viewRecent/" + id, data,
      { headers: { Authorization: "bearer " + authToken } }
      );
    } catch (error) {
      setError("Failed to fetch data");
      console.error('Failed to fetch items', error);
    } finally {
      othersetloading(false);
    }
      
    
  }

  useEffect(() => {
    if(authToken){
      viewRecent()
    }
  },[])

  if (loading) {
    return <div>Cargando...</div>;
  }

  return (
    <>
      <Navbar />
      <div className="infoCar-container">
        <div className="columns-info">
          <button className="change-set" onClick={toggleComponent}>
            Vista Completa
          </button>
          <Popup360 link360={link360} />
          <div
            className="normal-set"
            style={{ display: showComponentA ? "block" : "none" }}
          >
            <h1>
              {car.marca} {car.modelo} {car.anio}
            </h1>
            <div className="images-info">
              {mainImage ? (
                <center>
                  <img src={mainImage} alt="" className="main-image" />
                </center>
              ) : (
                <p></p>
              )}
            </div>
            <div className="images-set-container">
              <div
                style={{
                  display: "flex",
                  gap: "10px",
                  maxWidth: "600px",
                  overflowX: "auto",
                }}
              >
                {images.map((image, index) => (
                  <img
                    key={index}
                    src={image}
                    alt={`Thumbnail ${index}`}
                    onClick={() => handleImageClick(image)}
                    style={{
                      maxWidth: "500px",
                      height: "100px",
                      cursor: "pointer",
                    }}
                  />
                ))}
              </div>
            </div>
          </div>
          <div
            className="column-set"
            style={{
              display: showComponentA ? "none" : "flex",
              flexDirection: "column",
            }}
          >
            <h1 className="car-title-info">
              {car.marca} {car.modelo} {car.anio}
            </h1>
            <div className="images-info-2">
              {images.map((image, index) => (
                <img
                  key={index}
                  src={image}
                  alt={`Thumbnail ${index}`}
                  className="columset-img"
                />
              ))}
            </div>
          </div>
        </div>
        <div className="columns-car-info">
        {car.tipo !== 'Varios' && (<div className="column-element-info">
            <p className="precio-info">Lote:</p>
            <p className="precio-info">{car.lote}</p>
          </div>)}
          <div className="column-element-info">
            <p className="precio-info" spellcheck="false" translate="no">Precio:</p>
            <p className="precio-info" spellcheck="false" translate="no">
              Lps. {formatNumberWithCommas(car.offerta ? car.precioOferta : car.precio)}
            </p>
          </div>
          <div className="column-element-info">
            <p className="title-element">Fecha de publicación:</p>
            <p className="date">{formatDate(car.createdAt)}</p>
          </div>
          {car.tipo !== 'Varios' ? (<div className="column-element-info">
            <p className="title-element">Marca:</p>
            <p className="content-element">{car.marca}</p>
          </div>) : (
            <div className="column-element-info">
            <p className="title-element">Producto:</p>
            <p className="content-element">{car.marca}</p>
          </div>
          )}
          <div className="column-element-info">
            <p className="title-element">Modelo:</p>
            <p className="content-element">{car.modelo}</p>
          </div>
          <div className="column-element-info">
            <p className="title-element">Año:</p>
            <p className="content-element">{car.anio}</p>
          </div>
          {car.millaje !== 0 && (<div className="column-element-info">
            <p className="title-element">Millaje:</p>
            <p className="content-element">{car.millaje}</p>
          </div>)}
          <div className="column-element-info">
            <p className="title-element">Condición:</p>
            <p className="content-element">{car.condicion}</p>
          </div>
          <div className="column-element-info">
            <p className="title-element">Tipo:</p>
            <p className="content-element">{car.tipo}</p>
          </div>
          <div className="column-element-info">
            <p className="title-element">Ubicación:</p>
            <p className="content-element">{car.ubicacion}</p>
          </div>
          {car.motor !== '0' && (<div className="column-element-info">
            <p className="title-element">Motor:</p>
            <p className="content-element">{car.motor}</p>
          </div>)}
          {car.combustible !== 'N/A' && (<div className="column-element-info">
            <p className="title-element">Combustible:</p>
            <p className="content-element">{car.combustible}</p>
          </div>)}
          {car.transmission !== 'N/A' && (<div className="column-element-info">
            <p className="title-element">Transmición:</p>
            <p className="content-element">{car.transmission}</p>
          </div>)}
          {car.traccion !== 'N/A' && (<div className="column-element-info">
            <p className="title-element">Tracción:</p>
            <p className="content-element">{car.traccion}</p>
          </div>)}
          {car.listado && (<div className="column-element-info">
            <p className="title-element">Listado:</p>
            <p className="content-element">{car.listado}</p>
          </div>)}
          <div className="column-element-info">
            <p className="title-element">Camina:</p>
            <p className="content-element">{checkBoolean(car.camina)}</p>
          </div>
          <div className="column-element-info">
            <p className="title-element">Enciende:</p>
            <p className="content-element">{checkBoolean(car.enciende)}</p>
          </div>
          <div className="column-element-info">
            <p className="title-element">Grua incluída:</p>
            <p className="content-element">{checkBoolean(car.gruaIncluida)}</p>
          </div>
          <div className="column-element-info">
            <p className="title-element">Gastos de introducción:</p>
            <p className="content-element">{checkBoolean(car.Introduccion)}</p>
          </div>
          <div className="column-element-info">
            <p className="title-element">Gastos de inscripción:</p>
            <p className="content-element">
              {checkBoolean(car.gastosDeInscripcion)}
            </p>
          </div>
          <div className="column-element-info">
            <p className="title-element">Costo de Placas:</p>
            <p className="content-element">{checkBoolean(car.costosPlacas)}</p>
          </div>
          <div className="column-element-info">
            <p className="title-element">Costo de Vías Públicas:</p>
            <p className="content-element">{checkBoolean(car.viasPublicas)}</p>
          </div>
          {car.comentario !== "" && (<div className="column-element-info">
            <p className="title-element">Comentario:</p>
            <p className="content-element">{car.comentario}</p>
          </div>)}
          <PopupLogged solicitarCompra={solicitarCompra}/>
        </div>
      </div>
      <div className="footer">
        <Foooter />
      </div>
      <div className="footer-movil">
        <FooterMovil />
      </div>
    </>
  );
}

export default InfoCar;
