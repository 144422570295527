import React, { useState } from "react";
import styled from "styled-components";
import axios from "axios";
import { useAuth } from "../context/AuthContext";
import { message } from "antd";

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContainer = styled.div`
  background: white;
  padding: 20px;
  border-radius: 8px;
  max-width: 500px;
  width: 100%;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  position: relative;
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  font-size: 16px;
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
`;

const ConfirmButton = styled.button`
  background: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 20px;
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
  pointer-events: ${(props) => (props.disabled ? "none" : "auto")};

  &:hover {
    background: #0056b3;
  }
`;

const CancelButton = styled.button`
  background:  #B81137;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 20px;
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
  pointer-events: ${(props) => (props.disabled ? "none" : "auto")};

  &:hover {
    background: #ff0000;
  }
`;

const Input = styled.input`
  width: calc(100% - 20px);
  padding: 10px;
  margin-top: 20px;
  border: 1px solid #ddd;
  border-radius: 5px;
`;

const ValueRemaining = styled.p`
  margin-top: 20px;
  font-weight: bold;
`;

const AbonoModal = ({ show, carro, onClose, onConfirm, ventaId }) => {
  const [input, setInput] = useState("");
  const { authToken } = useAuth();
  const [valueRemaining, setValueRemaining] = useState(carro.precio);

  const handleChange = (event) => {
    const value = event.target.value;
    setInput(value);
  };

  const handleConfirm = async () => {
    const dataventa = {
      carroID : carro._id
    }
    const res = await axios.post(process.env.REACT_APP_BACKENDIP + "ventas/comprabycar", dataventa)
    const ventaidentifier = res.data[0]._id;
    const data = {
      abono: input,
      identifierVenta: ventaidentifier,
      comprobantes: [],
      notiID: ''
    };
    try {
      await axios.post(process.env.REACT_APP_BACKENDIP + "ventas/abono/", data, { headers: { Authorization: "bearer " + authToken } });
      const newValueRemaining = valueRemaining - parseFloat(input);
      setValueRemaining(newValueRemaining);
      message.success("Abono registrado exitosamente");
      onClose(); // Actualizar el valor o cerrar el modal
    } catch (error) {
      console.error("Error al registrar el abono:", error);
      message.error("No se pudo registrar el abono");
    }
  };

  const handleCancel = async () => {
    const dataventa = {
      carroID : carro._id
    }
    const res = await axios.post(process.env.REACT_APP_BACKENDIP + "ventas/comprabycar", dataventa)
    const ventaidentifier = res.data[0]._id;
    const data = {
      identifierVenta: ventaidentifier,
      identifierCarro: carro._id
    };
    try {
      await axios.post(process.env.REACT_APP_BACKENDIP + "findspecial/cancelar/", data, { headers: { Authorization: "bearer " + authToken } });
      message.success("cancelacion registrada exitosamente");
      onClose(); // Actualizar el valor o cerrar el modal
    } catch (error) {
      console.error("Error al registrar el abono:", error);
      message.error("No se pudo registrar el abono");
    }
  };

  if (!show) {
    return null;
  }

  return (
    <Overlay>
      <ModalContainer>
        <CloseButton onClick={onClose}>X</CloseButton>
        <h2>Añadir solicitud de abono</h2>
        <div className="car-models-mod">
          <div className="head">
            <p className="car-title-mod">
              {carro.marca} {carro.modelo} {carro.anio}
            </p>
          </div>
        </div>
        <div className={`search-bar-mod`}>
          <Input
            type="text"
            id="Search"
            value={input}
            onChange={handleChange}
            placeholder="Monto del abono"
          />
        </div>
        <ValueRemaining>Valor restante: L. {valueRemaining}</ValueRemaining>
        <ConfirmButton onClick={handleConfirm} disabled={!input}>
          Confirmar
        </ConfirmButton>
        <div></div>
        <CancelButton onClick={handleCancel}>
          Cancelar Venta
        </CancelButton>
      </ModalContainer>
    </Overlay>
  );
};

export default AbonoModal;
